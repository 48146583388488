import { Network } from "../Network";

export const StakingTokenTypes = {
  cu: "CU",
  culp: "CULP",
  grail: "GRAIL",
  arb: "ARB",
  xai: "XAI",
  weth: "WETH",
  rbw: "RBW",
  unim: "UNIM",
};

export const ShadowcornTokenTypes = {
  dark: "Dark Marks",
};

// 7 days + 1 hour to show correct format
export const REWARDS_CLAIMABLE_AFTER = 1000 * 60 * 60 * 24 * 7 + 1000 * 60 * 60;

export const NOT_DELEGATED_BADGE_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const pools = [
  {
    pid: 0,
    stakedTokenName: "RBW",
    stakedTokenSymbol: "RBW",
    rewardTokenName: "RBW",
    stakedTokenDecimals: 18,
    tokenAddress: Network.Arbitrum.CUContractAddress,
    poolAddress: Network.Arbitrum.CUStakingContractAddress,
    weight: 0.84104289318,
    toFixed: 2,
  },
  {
    pid: 1,
    stakedTokenName: "RBWLP",
    stakedTokenSymbol: "RBWLP",
    rewardTokenName: "RBW",
    stakedTokenDecimals: 18,
    tokenAddress: Network.Arbitrum.CULPContractAddress,
    poolAddress: Network.Arbitrum.CULPStakingContractAddress,
    weight: 0.15895710681,
    toFixed: 6,
  },
];
export const rewardsArray = [
  {
    name: StakingTokenTypes.cu,
    address: Network.Arbitrum.CUContractAddress,
    rewardContractAddress: Network.Arbitrum.CURewardContract,
  },
  {
    name: StakingTokenTypes.grail,
    address: Network.Arbitrum.GRAILContractAddress,
    rewardContractAddress: Network.Arbitrum.GRAILRewardContract,
  },
  // {
  //   name: StakingTokenTypes.arb,
  //   address: Network.Arbitrum.ARBContractAddress,
  //   rewardContractAddress: Network.Arbitrum.ARBRewardContract,
  // },
  {
    name: StakingTokenTypes.xai,
    address: Network.Arbitrum.XAIContractAddress,
    rewardContractAddress: Network.Arbitrum.XAIRewardContract,
  },
];
const month = 86400 * 30; //2592000
export const months = [
  { value: 1 * month, label: "1 Month" },
  {
    value: 2 * month,
    label: "2 Months",
  },
  { value: 3 * month, label: "3 Months" },
  { value: 4 * month, label: "4 Months" },
  { value: 5 * month, label: "5 Months" },
  { value: 6 * month, label: "6 Months" },
  { value: 7 * month, label: "7 Months" },
  { value: 8 * month, label: "8 Months" },
  { value: 9 * month, label: "9 Months" },
  { value: 10 * month, label: "10 Months" },
  { value: 11 * month, label: "11 Months" },
  { value: 12 * month, label: "12 Months" },
];

export const activeBadgeList = {
  0: [
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "69",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "68",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "67",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "53",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "50",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "49",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "48",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "47",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "45",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "44",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "43",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "41",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "84",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "100",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "99",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "98",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "101",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "85",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "86",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "87",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "88",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "89",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "90",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "91",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "92",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "93",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "94",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "95",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "96",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "97",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "115",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "116",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "106",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "107",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "103",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "104",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "105",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "102",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "119",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "117",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "111",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "112",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "113",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "114",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "118",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "121",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "122",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "123",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "124",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "125",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "126",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.2,
      tokenId: "139",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "138",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "137",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "136",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "135",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "134",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "133",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "132",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "131",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "130",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "129",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "128",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "140",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "141",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "142",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "143",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "144",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "145",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "146",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "147",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "148",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "149",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "150",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "151",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "152",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "153",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "155",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "156",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "157",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "158",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "160",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "161",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "162",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "163",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "164",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "165",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "166",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "167",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "168",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "169",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "170",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "171",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "172",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "173",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "174",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "175",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "176",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "177",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "178",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "179",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "180",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "181",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "182",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "183",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "184",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "185",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "186",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.2,
      tokenId: "187",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "188",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "189",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "190",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "191",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "192",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "193",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "194",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "195",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "196",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "197",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "198",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "199",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "200",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "201",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "202",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "203",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "204",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "205",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "206",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "207",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "208",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "209",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "210",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "211",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "212",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
  ],
  1: [
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "69",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "68",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "67",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "53",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "50",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "49",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "48",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "47",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "45",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "44",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "43",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "41",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "84",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "100",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "99",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "98",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "101",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "85",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "86",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "87",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "88",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "89",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "90",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "91",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "92",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "93",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "94",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "95",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "96",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "97",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "115",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "116",
    },
    {
      boostFactor: 0.2,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "106",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "107",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "103",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "104",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "105",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "102",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "119",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "117",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "111",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "112",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "113",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "114",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "118",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "121",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "122",
    },
    {
      boostFactor: 0.075,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "123",
    },
    {
      boostFactor: 0.125,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "124",
    },
    {
      boostFactor: 0.05,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "125",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "126",
    },
    {
      boostFactor: 0.01,
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
      tokenId: "127",
    },
    {
      boostFactor: 0.2,
      tokenId: "139",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "138",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "137",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "136",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "135",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "134",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "133",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "132",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "131",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "130",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "129",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "128",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "140",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "141",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "142",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "143",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "144",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "145",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "146",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "147",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "148",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "149",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "150",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "151",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "152",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "153",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "155",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "156",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "157",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "158",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "160",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "161",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "162",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "163",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "164",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "165",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "166",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "167",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "168",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "169",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "170",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "171",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "172",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "173",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "174",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "175",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "176",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "177",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "178",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "179",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "180",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "181",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "182",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "183",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "184",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "185",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "186",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.2,
      tokenId: "187",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "188",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "189",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "190",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "191",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "192",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "193",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "194",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "195",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "196",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "197",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "198",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "199",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "200",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "201",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "202",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "203",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "204",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.01,
      tokenId: "205",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "206",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "207",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "208",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "209",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.05,
      tokenId: "210",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.075,
      tokenId: "211",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
    {
      boostFactor: 0.125,
      tokenId: "212",
      contractAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d",
    },
  ],
};
