import ConnectWallet from "./components/ConnectWallet";
import {
  POLYGON_NETWORK,
  WalletHook,
} from "./components/ConnectWallet/WalletHelper";
import { NetworkConfiguration } from "./components/Network";
import { CRYPTO } from "./consts/externalLinks";
import { EVENT_KEYS, PLAY_GAME } from "./consts/googleEvents";
// import { EventsPage } from "./views/Events";
import usePageTracking from "./helpers/usePageTracking";
// import { Leaderboard } from "./components/Leaderboard";
// import { Tutorials } from "./components/Tutorials";
// import { MiniGames } from "./components/MiniGames";
// import { Claims } from "./components/Claims";
import { BaseLayout } from "./layouts/BaseLayout";
// import { Inventory } from "./components/Profile/Inventory";
import { ProfileLayout } from "./layouts/ProfileLayout";
import { currentWalletAddressAtom, walletConnectedAtom } from "./store";
import { BugBounty } from "./views/BugBounty";
// import { BumpercornsHowTo } from "./views/BumpercornsHowTo";
// import { EventEnded } from "./views/EventEnded";
import { Home } from "./views/Home";
import { Licensing } from "./views/Licensing";
import { LeaderboardPage } from "./views/Leaderboard";
import { Oops } from "./views/Oops";
import { Billboard } from "./views/Billboard";
import { PrivacyPage } from "./views/PrivacyPolicy";
import { Proxy } from "./views/Proxy";
import { TermsPage } from "./views/Terms";
import { CUIPTermsPage } from "./views/CUIPTerms";
// import { UnicornParty } from "./views/UnicornParty";
// import { Claim } from "./views/claim";
// import { ExchangePage } from "./views/exchange";
import { RainbowRumble } from "./views/RainbowRumble";
import { RainbowRumbleWillReturn } from "./views/RainbowRumbleWillReturn";
import { Inventory } from "./views/profile/Inventory";
import { useSetAtom } from "jotai";
import { Navigate, Route, Routes } from "react-router-dom";
import { Bridge } from "./views/Bridge";
import { Staking } from "./views/staking";
import { StakingOverview } from "./views/staking/StakingOverview";
import { StakingRewards } from "./views/staking/StakingRewards";
import { StakingBadges } from "./views/staking/StakingBadges";
import { StakingHistory } from "./views/staking/StakingHistory";
import { StakingLeaderboard } from "./views/staking/StakingLeaderboard";
import { Airdrop } from "./views/Airdrop";
import { PlayerRewards } from "./views/PlayerRewards";

const BASE_ROUTES = [
  {
    path: "/leaderboard",
    component: <LeaderboardPage />,
  },
  // {
  //   path: "/exchange",
  //   component: <ExchangePage />,
  // },
  {
    path: "/terms-of-service",
    component: <TermsPage />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPage />,
  },
  {
    path: "/ip-terms",
    component: <CUIPTermsPage />,
  },
  {
    path: "/bug-bounty",
    component: <BugBounty />,
  },
  // {
  //   path: "/unicornparty/how-to-play",
  //   component: <BumpercornsHowTo />,
  // },
  // {
  //   path: "/events",
  //   component: <EventsPage />,
  // },
  // {
  //   path: "/explore",
  //   component: Tutorials,
  //   exact: true,
  // },
  // {
  //   path: "/corns-bulletin",
  //   component: MiniGames,
  //   exact: true,
  // },
  // {
  //   path: "/rewards",
  //   component: Claims,
  //   exact: true,
  // },
];

const PROFILE_ROUTES = [
  {
    path: "/profile/inventory",
    component: <Inventory />,
  },
];

// const CLAIM_ROUTES = [
//   {
//     path: "/claim",
//     component: <Claim />,
//   },
// ];

const STAKING_ROUTES = [
  {
    path: "/staking/overview",
    component: <StakingOverview />,
  },
  {
    path: "/staking/rewards",
    component: <StakingRewards />,
  },
  {
    path: "/staking/badges",
    component: <StakingBadges />,
  },
  {
    path: "/staking/deposits",
    component: <StakingHistory />,
  },
  {
    path: "/staking/leaderboard",
    component: <StakingLeaderboard />,
  },
];

const PROXY_ROUTES = [
  {
    path: "/proxy/bumpercorns",
    component: (
      <Proxy
        url={CRYPTO.BUMPERCORNS.APK}
        event={{
          name: EVENT_KEYS.PLAY_GAME,
          category: PLAY_GAME.BUMPERCORNS_APK,
        }}
      />
    ),
  },
  {
    path: "/proxy/mobrun",
    component: (
      <Proxy
        url={CRYPTO.MOB_RUN.APK}
        event={{
          name: EVENT_KEYS.PLAY_GAME,
          category: PLAY_GAME.MOB_RUN_APK,
        }}
      />
    ),
  },
  {
    path: "/proxy/billboardsf",
    component: (
      <Proxy
        to="/billboardsf?utm_source=inperson&utm_medium=billboard&utm_campaign=billboard_sf_2023&utm_id=bbsf08092023"
        event={{
          name: EVENT_KEYS.BILLBOARD_SF,
        }}
      />
    ),
  },
];

function App() {
  usePageTracking();
  //const [{ wallet }, connect] = useConnectWallet();
  //const [{ connectedChain }] = useSetChain();
  const setWalletConnected = useSetAtom(walletConnectedAtom);
  const setCurrentWalletAddress = useSetAtom(currentWalletAddressAtom);

  //
  //
  // //Not using a useEffect on this hook allows for
  // //instant updates when the user changes network/wallet
  //ConnectWallet({ wallet, connect });

  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  if (!netconfig) {
    console.error(
      `Unsupported polygon network: ${POLYGON_NETWORK}. Valid choices: mainnet, mumbai, ganache.`
    );
  }
  // WalletHook(
  //   wallet,
  //   netconfig,
  //   // connectedChain,
  //   setWalletConnected,
  //   setCurrentWalletAddress,
  // );

  const _baseRouteComponents = BASE_ROUTES.map(
    ({ path, component, exact }, key) => (
      <Route path={path} element={component} key={key} />
    )
  );

  const _profileRouteComponents = PROFILE_ROUTES.map(
    ({ path, component, exact }, key) => (
      <Route path={path} element={component} key={key} />
    )
  );

  // const _claimRouteComponents = CLAIM_ROUTES.map(
  //   ({ path, component, exact }, key) => (
  //     <Route path={path} element={component} key={key} />
  //   ),
  // );

  const _proxyRouteComponents = PROXY_ROUTES.map(
    ({ path, component, exact }, key) => (
      <Route path={path} element={component} key={key} />
    )
  );

  const _stakingRouteComponents = STAKING_ROUTES.map(
    ({ path, component, exact }, key) => (
      <Route path={path} element={component} key={key} />
    )
  );

  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/airdrop"} element={<Airdrop />} />
      <Route path={"/claim"} element={<PlayerRewards />} />
      <Route path="/claims" element={<Navigate to="/claim" replace />} />
      {/* failsafe since the title is claims  */}
      {/* <Route path={"/unicornparty"} element={<UnicornParty />} /> */}
      <Route path={"/billboardsf"} element={<Billboard />} />
      <Route path={"/licensing"} element={<Licensing />} />
      <Route path={"/"} exact>
        <Route element={<BaseLayout />}> {_baseRouteComponents}</Route>
      </Route>
      {/* <Route path={"/profile"} exact>
        <Route element={<ProfileLayout />}>{_profileRouteComponents}</Route>
      </Route> */}
      {/* <Route path={"/claim"} element={<BaseLayout />} exact>
        {_claimRouteComponents}
      </Route> */}
      <Route path={"/proxy"} exact>
        {_proxyRouteComponents}
      </Route>
      <Route path={"/chasetherainbow"} element={<RainbowRumble />} />
      <Route path={"/rrwillreturn"} element={<RainbowRumbleWillReturn />} />
      <Route path={"/bridge"} element={<Bridge />} />
      <Route path={"/staking"} element={<Staking />} exact>
        <Route index element={<Navigate to="/staking/overview" replace />} />
        {_stakingRouteComponents}
      </Route>
      <Route path={"*"} element={<Oops />} />
    </Routes>
  );
}

export default App;
