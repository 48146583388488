import styled from "styled-components";
import { Container } from "../../base/Container";
import { GamePagination } from "./GamePagination";
import { forwardRef, useState, useEffect } from "react";
import { GameCarousel } from "./GameCarousel";
import { device } from "../../../theme/mediaQuery";
import { useAtomValue, useSetAtom } from "jotai/index";
import { scrollAtom } from "../../../store/scroll";
import { GameBanner } from "../../rainbowRumble/GameBanner";
import {
  TRIBES_CARD,
  JOUSTING_CARD,
  LAND_CARD,
  SC_CARD,
  RR_CARD,
} from "../../rainbowRumble/GameBannerCopy";
import { GamesUnicornParty } from "./GamesUnicornParty";

const GAMES = [
  {
    id: "tribes",
    title: "Tribes",
    icon: "tribes",
    index: 0,
  },
  {
    id: "land",
    title: "Land Gameplay",
    icon: "leaves",
    index: 1,
  },
  {
    id: "jousting",
    title: "Jousting",
    icon: "jousting",
    index: 2,
  },
  {
    id: "shadowcorn",
    title: "Shadowcorn",
    icon: "eyes",
    index: 3,
  },
  {
    id: "rainbow-rumble",
    title: "Rainbow Rumble",
    icon: "rainbow-rumble",
    index: 4,
  },
  // {
  //   id: "unicorn-party",
  //   title: "Unicorn Party",
  //   icon: "stamps",
  //   index: 5,
  // },
];

export const getGameIndexById = (id) => {
  const game = GAMES.find((game) => game.id === id);
  return game ? game.index : -1;
};

const SLIDES = [
  TRIBES_CARD,
  LAND_CARD,
  JOUSTING_CARD,
  SC_CARD,
  { ...RR_CARD, title: "Rainbow Rumble" },
];

const SectionWrap = styled.section`
  padding: 90px 0;
  background: ${({ theme }) => theme.palette.indigo700};
  position: relative;
  overflow: hidden;
  @media (${device.lg}) {
    padding: 170px 0;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${({ theme }) => theme.pattern.petroglyph});
    background-size: 20%;
    opacity: 0.03;
  }
`;

const SectionHeader = styled.div``;

const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.display};
  color: ${({ theme }) => theme.palette.white};
  text-align: center;

  @media (${device.lg}) {
    font-size: 60px;
  }
`;

const GameBannerWrap = styled.div`
  padding: 0 20px;
`;

const HomeGamesSection = forwardRef((props, ref) => {
  const { activePage: scrollActivePage } = useAtomValue(scrollAtom);
  const setScroll = useSetAtom(scrollAtom);
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    if (scrollActivePage !== undefined) {
      setActivePage(scrollActivePage);
    }
  }, [scrollActivePage]);

  const onPageChange = (page) => {
    setActivePage(page);
    setScroll({ scroll: false, activePage: undefined });
  };

  return (
    <SectionWrap ref={ref} id="play-our-game">
      <Container>
        <SectionHeader>
          <SectionTitle>Play Our Games</SectionTitle>
          <GamePagination
            games={GAMES}
            active={activePage}
            onChange={onPageChange}
          />
          <GameCarousel active={activePage} onSlideChange={onPageChange}>
            {SLIDES.map((game, i) => (
              <GameBannerWrap key={`carousel-card-${i}`}>
                <GameBanner
                  titleColor="white"
                  tagsColor="indigo300"
                  {...game}
                />
              </GameBannerWrap>
            ))}
            <GameBannerWrap>
              <GamesUnicornParty />
            </GameBannerWrap>
          </GameCarousel>
        </SectionHeader>
      </Container>
    </SectionWrap>
  );
});
export { HomeGamesSection };
