import styled from "styled-components";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { NavMenuSectionLink } from "./NavMenuSectionLink";
import { NavMenuCardFooter } from "./NavMenuCardFooter";
import { device } from "../../theme/mediaQuery";
import {
  BUY,
  CRYPTO,
  WHITE_PAPER,
  COMMUNITY,
} from "../../consts/externalLinks";

const MARKETPLACE_SECTION = {
  title: "Shop",
  links: [
    {
      title: "Marketplace",
      subtitle: "Start your journey, or power up!",
      path: BUY.MARKETPLACE.HOME,
    },
    {
      title: "Land Vending Machine",
      subtitle: "Mint new land with a keystone!",
      path: CRYPTO.VENDING,
    },
    {
      title: "CU Merch",
      subtitle: "Gear up with unicorn essentials",
      path: CRYPTO.SHOP,
    },
    {
      title: "Golden Ticket Marketplace",
      subtitle: "Coming soon! 👀",
      //path: "#"
      disabled: true,
    },
  ],
};

const TOKENS_SECTION = {
  title: "Tokens",
  links: [
    {
      title: "Our Tokens",
      subtitle: "Discover our in-game tokens",
      path: WHITE_PAPER.TOKENOMICS,
    },
    {
      title: "Staking",
      subtitle: "Stake our tokens & earn rewards",
      path: "/staking/overview",
    },
    {
      title: "Buy CU",
      subtitle: "Get CU from Camelot",
      path: BUY.CU,
    },
    {
      title: "Buy UNIM",
      subtitle: "Get UNIM from Camelot",
      path: BUY.UNIM,
    },
  ],
};

const LEADERBOARD_SECTION = {
  title: "Game Tools",
  links: [
    {
      title: "Claim",
      subtitle: "Claim your rewards",
      path: "/claim",
    },
    {
      title: "Leaderboards",
      subtitle: "Participate in events and earn rewards",
      path: "/leaderboard",
    },
    // {
    //   title: "Ticket Exchange",
    //   subtitle: "Buy Unicorn Party Game Tickets",
    //   path: "/exchange",
    // },
    {
      title: "Rainbow Bridge",
      subtitle: "Bridge from Polygon to Arbitrum",
      path: "/bridge",
    },
  ],
};

const FOOTER_CARDS = [
  {
    title: "Play our games",
    description: "Explore all our games filled with unicorns!",
    icon: "games",
    link: {
      title: "View All Our Games",
      path: "/",
    },
    colors: {
      background: "linearPinkLight",
      iconBackground: "#ED1C8C",
    },
  },
  {
    title: "Learn Tips and Tricks",
    description: "Check out our game tutorials!",
    icon: "book",
    link: {
      title: "Visit our Gitbook",
      path: WHITE_PAPER.LEARN,
    },
    colors: {
      background: "linearMintLight",
      iconBackground: "#0CA28E",
    },
  },
  {
    title: "Learn from the community",
    description: "Join our Discord for insider game tips!",
    icon: "discord",
    link: {
      title: "Join Our Discord",
      path: COMMUNITY.DISCORD,
    },
    colors: {
      background: "linearBlueLight",
      iconBackground: "#3E4DF1",
    },
  },
];

const SECTIONS = [MARKETPLACE_SECTION, TOKENS_SECTION, LEADERBOARD_SECTION];

const Wrap = styled.nav`
  padding: 30px 12px;

  @media (${device.lg}) {
    padding: 32px 26px;
  }
`;

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

const SectionHeading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.darkBlue300};
  margin-bottom: 20px;
  padding: 0 10px;
`;

export function NavGameHubMenu({ scrollToGame }) {
  return (
    <Wrap>
      <Row>
        {SECTIONS.map((section) => (
          <Col key={section.title} xs={12} md={4}>
            <SectionWrap>
              <SectionHeading>{section.title}</SectionHeading>
              {section.links.map((link) => (
                <NavMenuSectionLink
                  key={link.title}
                  path={link.path ? link.path : null}
                  title={link.title}
                  subtitle={link.subtitle}
                  disabled={link?.disabled}
                />
              ))}
            </SectionWrap>
          </Col>
        ))}
      </Row>
      <NavMenuCardFooter cards={FOOTER_CARDS} />
    </Wrap>
  );
}
