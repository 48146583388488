import styled from "styled-components";
import { Row } from "../base/Row";
import { Col } from "../base/Col";
import { Typography } from "../base/Typography";
import land_game from "../../img/games/land.png";
import jousting from "../../img/games/jousting.png";
import shadowcorns from "../../img/games/shadowcorns.png";
import rainbow_rumble from "../../img/rainbowRumble/rainbow-rumble.png";
import tribes from "../../img/games/tribes.png";
import { NavMenuCardFooter } from "./NavMenuCardFooter";
import { Link } from "react-router-dom";
import { device } from "../../theme/mediaQuery";
import { WHITE_PAPER, CRYPTO, COMMUNITY } from "../../consts/externalLinks";
import { useSetAtom } from "jotai";
import { scrollAtom } from "../../store";
import { getGameIndexById } from "../home/games";
//do we want to link people to the darkforest?

const GAMES = [
  {
    title: "Land Gameplay",
    description:
      "Start Farming, Crafting & Unicorn Breeding to build your empire!",
    image: land_game,
    url: CRYPTO.LAND_GAME,
    label: null,
  },
  {
    title: "Jousting",
    description:
      "Lead your majestic Unicorns into thrilling PvP duels in the Jousting Arena!",
    image: jousting,
    url: CRYPTO.JOUSTING,
    label: null,
    target: "_blank",
  },
  {
    title: "Shadowcorns",
    description:
      "Command an army of minions & unleash their fearsome power in the Dark Forest.",
    image: shadowcorns,
    linkTitle: "Visit the Dark Forest",
    url: CRYPTO.SHADOWCORNS,
    label: null,
    target: "_blank",
  },
  // {
  //   title: "Unicorn Party",
  //   description:
  //     "Play a suite of real-time skill based hybrid casual PvP games!",
  //   image: unicorn_party,
  //   label: "new-game",
  //   url: "/unicornparty",
  // },
  {
    title: "Rainbow Rumble",
    description:
      "Compete against Unicorns in survival style races across epic obstacle course.",
    image: rainbow_rumble,
    linkTitle: "Play Now",
    // scroll: "rainbow-rumble",
    url: "/",
    label: "new-game",
    url: CRYPTO.RAINBOW_RUMBLE,
    target: "_blank",
  },
  {
    title: "Tribes",
    description:
      "Join a Tribe to craft Gems, upgrade buildings, and compete in Tribe vs Tribe PvP leaderboard events.",
    image: tribes,
    url: CRYPTO.LAND_GAME,
    target: "_blank",
  },
];

const FOOTER_CARDS = [
  {
    title: "Play our games",
    description: "Explore all our games filled with unicorns!",
    icon: "games",
    link: {
      title: "View All Our Games",
      path: "/",
    },
    colors: {
      background: "linearPinkLight",
      iconBackground: "#ED1C8C",
    },
  },
  {
    title: "Learn Tips and Tricks",
    description: "Check out our game tutorials!",
    icon: "book",
    link: {
      title: "Visit our Gitbook",
      path: WHITE_PAPER.LEARN,
    },
    colors: {
      background: "linearMintLight",
      iconBackground: "#0CA28E",
    },
  },
  {
    title: "Learn from the community",
    description: "Join our Discord for insider game tips!",
    icon: "discord",
    link: {
      title: "Join Our Discord",
      path: COMMUNITY.DISCORD,
    },
    colors: {
      background: "linearBlueLight",
      iconBackground: "#3E4DF1",
    },
  },
];

const LINK_LABELS = {
  "new-game": {
    label: "New Game",
    color: "appleGreen300",
  },
  "limited-time": {
    label: "Limited Time",
    color: "fuschia200",
  },
  "event-ended": {
    label: "Event Ended",
    color: "gray300",
  },
  community: {
    label: "Community Run",
    color: "shyOrange300",
  },
};

const NavGamesMenuWrap = styled.nav`
  padding: 12px 12px;

  @media (${device.lg}) {
    padding: 32px 26px;
  }
`;

const GameImage = styled.img`
  width: 100%;
  border-radius: 8px;
`;

const GameLink = styled.div`
  font-family: ${({ theme }) => theme.fonts.display};
  font-size: 14px;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.palette.gray350 : theme.palette.blue200};
  text-decoration: none;
  padding-top: 12px;
`;

const GameWrap = styled(Link)`
  text-decoration: none;
  transition: background 0.2s ease-in-out;
  display: block;
  padding: 12px;
  border-radius: 8px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  filter: ${({ $disabled }) =>
    $disabled ? "grayscale(0.5)" : "theme.palette.blue200"};
  &:hover {
    background: ${({ theme, $disabled }) =>
      $disabled ? "none" : theme.palette.slate50};
  }

  @media (${device.xl}) {
    padding: 0 12px;
  }
`;

const GameDetailWrap = styled.div`
  padding: 10px 0;
`;

const GameTitleWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 8px;
`;

const LinkLabelWrap = styled(Typography)`
  background: ${({ theme, $color }) => theme.palette[$color]};
  font-size: 10px;
  line-height: 11px;
  padding: 4px 6px;
  border-radius: 4px;
`;

const GamesGridRow = styled(Row)`
  row-gap: 8px;
  padding: 0 6px;
  @media (${device.lg}) {
    row-gap: 16px;
    padding: 0 16px;
  }
`;

export function NavGamesMenu({ scrollToGame }) {
  const setScroll = useSetAtom(scrollAtom);

  const scrollToTab = (game) => {
    const tab = getGameIndexById(game);
    setScroll({ scroll: true, activePage: tab });
  };

  return (
    <NavGamesMenuWrap>
      <GamesGridRow>
        {GAMES.map((game, index) => (
          <Col key={game.title} xs={12} lg={6} xl={4} className="p-0">
            <GameWrap
              to={game.url ? game.url : "#"}
              target={game.target ? game.target : null}
              $disabled={game?.disabled ? true : false}
              onClick={game?.scroll ? () => scrollToTab(game.scroll) : null}
            >
              <Row className="align-items-center mx-0">
                <Col xs={4} sm={3} xxl={3} className="p-0">
                  <GameImage src={game.image} alt={game.title} />
                </Col>
                <Col xs={8} sm={9} xxl={9}>
                  <GameDetailWrap>
                    <GameTitleWrap>
                      <Typography variant="textLg" color="darkBlue300" tag="h3">
                        {game.title}
                      </Typography>
                      <LinkLabel variant={game.label} />
                    </GameTitleWrap>
                    <Typography
                      variant="textXs"
                      color="gray500"
                      tag="p"
                      weight={600}
                    >
                      {game.description}
                    </Typography>
                    <GameLink $disabled={game?.disabled ? true : false}>
                      {game.linkTitle || "Play Now"}
                    </GameLink>
                  </GameDetailWrap>
                </Col>
              </Row>
            </GameWrap>
          </Col>
        ))}
      </GamesGridRow>
      <NavMenuCardFooter cards={FOOTER_CARDS} />
    </NavGamesMenuWrap>
  );
}

export const LinkLabel = ({ variant, ...rest }) => {
  const label = variant
    ? LINK_LABELS[variant] || LINK_LABELS["new-game"]
    : variant;

  return (
    <>
      {variant && (
        <LinkLabelWrap
          variant="displaySm"
          tag={"span"}
          family={"display"}
          lg={{
            variant: "text2xs",
          }}
          $color={label.color}
          {...rest}
        >
          {label.label}
        </LinkLabelWrap>
      )}
    </>
  );
};
