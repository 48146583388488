import { Col } from "../../base/Col";
import { Row } from "../../base/Row";
import { GameBanner } from "../../rainbowRumble/GameBanner";
import {
  BUMPERCORNS_CARD,
  MOBRUN_CARD,
} from "../../rainbowRumble/GameBannerCopy";

export function GamesUnicornParty({ size = "lg" }) {
  return (
    <Row>
      <Col xs={12} className="mb-5">
        <GameBanner
          titleColor="white"
          tagsColor="indigo300"
          size={size}
          {...BUMPERCORNS_CARD}
        />
      </Col>
      <Col xs={12} className="mb-5">
        <GameBanner
          titleColor="white"
          tagsColor="indigo300"
          size={size}
          {...MOBRUN_CARD}
        />
      </Col>
    </Row>
  );
}
