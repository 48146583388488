export const NetworkConfiguration = {
  mainnet: {
    networkId: 137,
    networkIdArbitrum: 42161,
    networkIdXai: 660279,
    CUContractAddress: "0xdC0479CC5BbA033B3e7De9F178607150B3AbCe1f",
    CULandAddress: "0xA2a13cE1824F3916fC84C65e559391fc6674e6e8",
    CUTerminusAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d", // Items
    ShadowcornContractAddress: "0xa7D50EE3D7485288107664cf758E877a0D351725",
    ShadowcornItemsContractAddress:
      "0xb27Bbc8F0092284a880d1616f184D86c1a640faE", // Shadowcorn Items
    GOFPContractAddress: "0xDD8bf70a1f3A5557CCaB839E46cAB5533955Da65",
    //Three dropper contracts
    CUDropperV1Address: "0x6bc613A25aFe159b70610b64783cA51C9258b92e", //V1 Leaderboard
    CUDropperV2Address: "0xbB642692990577e1Ec9354865e7dF9ADB3AcC747", //V2 Leaderboard
    SatelliteBankAddress: "0x28597eA60030fBae79088d89d803f25143c7a6B5", //V2 2P games
    //Dropper ID's for moonstream api
    DROPPER_BASE_URL: "https://engineapi.moonstream.to",
    DROPPER_CONTRACT_ID: "12c53f27-666d-4321-bacb-2eed3ea43c09", //V1 Leaderboard
    DROPPER_CONTRACT_ID_V2_2P: "797cdc3f-cc14-468e-b62b-46f73d2e3387", //V2 2P games
    DROPPER_CONTRACT_ID_V2_LEADERBOARD: "797cdc3f-cc14-468e-b62b-46f73d2e3387", //V2 Leaderboard
    //
    DROPPER_BLOCKCHAIN_NAME: "polygon",
    UNIMContractAddress: "0x64060aB139Feaae7f06Ca4E63189D86aDEb51691",
    RBWContractAddress: "0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f",
    WETHContractAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    MATICContractAddress: "0x0000000000000000000000000000000000001010",
    CU2PGameBankContractAddress: "0x28597eA60030fBae79088d89d803f25143c7a6B5",
    RBWAddress: "0x431CD3C9AC9Fc73644BF68bF5691f4B83F9E104f",
    // Rituals
    unimAddress: "0x64060aB139Feaae7f06Ca4E63189D86aDEb51691",
    rbwcAddress: "0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f",
    darkMarksAddress: "0x6658687f201C4A073DfF38fdAAd050f420B45CA6",
    hatcheryAddress: "0x94f84d94A1b8Ce60C5F99eAF89b4679bf9Bf598c",
    itemsAddress: "0xb27Bbc8F0092284a880d1616f184D86c1a640faE",
    unicornItemsAddress: "0x99A558BDBdE247C2B2716f0D4cFb0E246DFB697D",
    ritualsAddress: "0xF0648328101F0a18469401a1dbf52CDF82a7fE45",
    ritualsGenericAddress: "0x765987A1bAB3FC8fcfD1e9D69a7Ec522f3c480c2",
    ArbitrumRPC: `https://42161.rpc.thirdweb.com/${process.env.REACT_APP_CLIENT_ID}`, //"https://arb1.arbitrum.io/rpc",
    PolygonRPC: "https://polygon-rpc.com",
    XaiRPC: `https://660279.rpc.thirdweb.com/${process.env.REACT_APP_CLIENT_ID}`, //"https://xai-chain.net/rpc",
    dstChainIdArbitrum: 110,
    RBWArbitrum: "0x89C49A3fA372920aC23ce757A029e6936c0b8e02",
    RBWBridgeContractAddress: "0xa6465c10f60ef3257e3e6f529d1ceb69408f7c6f",
    UNIMBridgeContractAddress: "0x56f1D1452C546798D4FDf3197AE7f33ADAbD196B",
    dstChainIdArbitrum: 110,
    dstChainIdXai: 236,
    bridgeGas: 200000,
    Arbitrum: {
      CamelotRouterAddress: "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
      ETHContractAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      CUContractAddress: "0x89C49A3fA372920aC23ce757A029e6936c0b8e02",
      CULPContractAddress: "0x75e1a0dda0f7d3ca5d8960875e306533e7259609",
      terminusContractAddress: "0x1a697d4d9B12c53dc8cC89356DDce1B658851E01",
      badgeManagerCU: "0x919951bC88ADE76cc911F09bb50e81a91325Aa21",
      badgeManagerCULP: "0x79cBc5EC7Caa32705D4f96BA3d012Babbb6a7344",
      CUStakingContractAddress: "0x29b9fd9941caA27c1D778AbB5fddafE85821283E",
      CULPStakingContractAddress: "0x942bE15783585A542F77289f0723A7ee29E66c2e",
      viewContractAddress: "0x5fb1BFb423eCC81AAcfFDF45e0675f86baADBAD9",
      CURewardContract: "0x263A00d1fFe57fd3aBC5e87E4A475e1341Cb769F",
      XAIRewardContract: "0xCF86AD2477c72E0490765BA8f04B97A03FDd7b9d",
      GRAILRewardContract: "0xA1a194c6B315A62d90f420046801d6bbe228d121",
      ARBRewardContract: "0x22bB102b84735564DA3a394bAFcAFeE049467dC8",
      XAIContractAddress: "0x4Cb9a7AE498CEDcBb5EAe9f25736aE7d428C9D66",
      GRAILContractAddress: "0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8",
      ARBContractAddress: "0x912CE59144191C1204E64559FE8253a0e49E6548",
      DropperV2Address: "0x75dd13a516c6C00650E254C47d8bBFB0092Af1bb",
      DropperID: "a2e050b8-c4e5-4025-a6c8-27f288df958b",
    },
    Xai: {
      unimAddress: "0x64060aB139Feaae7f06Ca4E63189D86aDEb51691",
      wCUAddress: "0x89C49A3fA372920aC23ce757A029e6936c0b8e02",
    },
    clientID: process.env.REACT_APP_CLIENT_ID,
  },
  mumbai: {
    networkId: 80002,
    networkIdArbitrum: 421614,
    CUContractAddress: "0x39858b1A4e48CfFB1019F0A15ff54899213B3f8b",
    CULandAddress: "0x230E4e85d4549343A460F5dE0a7035130F62d74C",
    // CULandAddress: "0x54198b8A8e4DE7C55Fa8Bf7862C885b848673548",
    // CUTerminusAddress: "0x99a558bdbde247c2b2716f0d4cfb0e246dfb697d", // MAINNET: Items
    CUTerminusAddress: "0x19e812EdB24B68A8F3AbF5e4C82d10AfEf1641Db", // MUMBAI: Unicorn Items
    ShadowcornContractAddress: "0x8819CFdb4Fd6Ba0464Ef2283F5F621443B7eC2F4",
    ShadowcornItemsContractAddress:
      "0xaCB3f893986A18E518270D00fdc055E0b85F4203", // MUMBAI: Shadowcorn Items
    GOFPContractAddress: "0x8b9493d84e70e94ff9EB1385aD0ed632FD5edE13", // New
    //Three dropper contracts
    CUDropperV1Address: "0xf9F67e006340880FFfe1325e2bC3c35Faad48e65", // Airdrop Mumbai
    SatelliteBankAddress: "0x749a38526e9d353F47998F7804c4d666F7AF0E6D", //DropperV2 2P
    CUDropperV2Address: "0xF5C7D54B752531Cf980bFA681d02Dfe3eB5d9511", //DropperV2 Leaderboard
    //Dropper Id's for moonstream api
    DROPPER_BASE_URL: "https://engineapi.moonstream.to",
    DROPPER_CONTRACT_ID: "76fb9be5-e086-4d18-b170-902e304f1e47", //V1 Leaderboard
    DROPPER_CONTRACT_ID_V2_2P: "5e3a6b8d-b704-4995-bc78-d43a6e8a7338", //V2 2P Games
    DROPPER_CONTRACT_ID_V2_LEADERBOARD: "5e3a6b8d-b704-4995-bc78-d43a6e8a7338", //V2 Leaderboard
    //
    DROPPER_BLOCKCHAIN_NAME: "mumbai",
    UNIMContractAddress: "0x28DA0Cad162dfd305280b956d93E0cd9F10C7d73",
    RBWContractAddress: "0x4Df452487E6c9d0C3Dc5EB4936244F8572b3F0b6",
    WETHContractAddress: "0xC17AF33DaBd645f968d5E765e68ecf8d3DC401Ea",
    MATICContractAddress: "0x0000000000000000000000000000000000001010",
    CU2PGameBankContractAddress: "0x749a38526e9d353F47998F7804c4d666F7AF0E6D",
    RBWAddress: "0x4Df452487E6c9d0C3Dc5EB4936244F8572b3F0b6",
    RBWBridgeContractAddress: "0x451cfD62E21Ba146019Ab9b99335eeEBEEf500A4",
    UNIMBridgeContractAddress: "0x60036FAAF5729B03e4ED468f55FE5fe3cEFf273C",
    RBWArbitrum: "0xd781cea0b8D5dDd0aeeD1dF7aC109C974A221B00",
    UNIMXai: "0x6ebe7cF85326B8441094BDF40f76c0d9c9a2c134",
    dstChainIdArbitrum: 10231,
    dstChainIdXai: 10251,
    bridgeGas: 200000,
    // Rituals
    unimAddress: "0x47d0f0BD94188e3f8c6fF2C0B1Bf7D6D8BED7534",
    rbwcAddress: "0x4Df452487E6c9d0C3Dc5EB4936244F8572b3F0b6",
    darkMarksAddress: "0xF224364450b81E85E002DeE451E478D1d5De71A8",
    itemsAddress: "0xaCB3f893986A18E518270D00fdc055E0b85F4203",
    unicornItemsAddress: "0x19e812edb24b68a8f3abf5e4c82d10afef1641db",
    ritualsAddress: "0x60b40Ff38040F7AcC2BE120a36027469596b17d0",
    ritualsGenericAddress: "0xc459feEF2d2ba3EAB97C93E7FB19598214511045",
    ArbitrumRPC: "https://sepolia-rollup.arbitrum.io/rpc",
    Arbitrum: {
      CamelotRouterAddress: "0xc873fEcbd354f5A56E00E710B90EF4201db2448d",
      ETHContractAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      CUContractAddress: "0x89C49A3fA372920aC23ce757A029e6936c0b8e02",
      CULPContractAddress: "0x75e1a0dda0f7d3ca5d8960875e306533e7259609",
      terminusContractAddress: "0x6cCf73B595a3878a4d98C1A7692C93db2aC6674C", //Only correct address for sepolia
      badgeManagerCU: "0x919951bC88ADE76cc911F09bb50e81a91325Aa21",
      badgeManagerCULP: "0x79cBc5EC7Caa32705D4f96BA3d012Babbb6a7344",
      CUStakingContractAddress: "0x29b9fd9941caA27c1D778AbB5fddafE85821283E",
      CULPStakingContractAddress: "0x942bE15783585A542F77289f0723A7ee29E66c2e",
      viewContractAddress: "0x5fb1BFb423eCC81AAcfFDF45e0675f86baADBAD9",
      CURewardContract: "0x263A00d1fFe57fd3aBC5e87E4A475e1341Cb769F",
      XAIRewardContract: "0xCF86AD2477c72E0490765BA8f04B97A03FDd7b9d",
      GRAILRewardContract: "0xA1a194c6B315A62d90f420046801d6bbe228d121",
      ARBRewardContract: "0x22bB102b84735564DA3a394bAFcAFeE049467dC8",
      XAIContractAddress: "0x4Cb9a7AE498CEDcBb5EAe9f25736aE7d428C9D66",
      GRAILContractAddress: "0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8",
      ARBContractAddress: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    },
    Xai: {
      unimAddress: "0xA181b7496b5F72C86AE5776778225d36f06031CE",
      wCUAddress: "0xE267C440dbFb1e185D506C2CC3C44eB21340E046",
    },
    clientID: process.env.REACT_APP_CLIENT_ID,
  },
};

export const isTestnet = process.env.REACT_APP_POLYGON_NETWORK !== "mainnet";
export const Network = isTestnet
  ? NetworkConfiguration.mumbai
  : NetworkConfiguration.mainnet;

export const DexScreenerTokenPairs = {
  CU_WETH: "0x75E1a0ddA0F7d3ca5D8960875e306533e7259609",
  WETH_USDT: "0x641C00A822e8b671738d32a431a4Fb6074E5c79d",
  ARB_WETH: "0xC6F780497A95e246EB9449f5e4770916DCd6396A",
  GRAIL_WETH: "0x7244BCf6226077c393B7c98c0CeC6C8C3cE9427e",
  XAI_WETH: "0x03A33AEE5F4947B9C1CDe042C5f037D1be304Be7",
};
