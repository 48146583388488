import {
  StakingOverviewStats,
  StakingOverviewStatsFullRow,
  StakingOverviewStatsGrid,
  StakingOverviewStatsSection,
} from "./StakingOverviewStats";
import { Button } from "../../base/Button";
import styled from "styled-components";
import { StakingRainbowBorder } from "../StakingRainbowBorder";
import { LiquidityModal } from "../Modals/LiquidityModal";
import { StakingTokenTypes } from "../constants";
import { LiquiditySuccessModal } from "../Modals/LiquiditySuccessModal";
import { FormattedAmount } from "../../common/FormattedAmount";
import { formatStringWithFixedFraction } from "../../Helpers";
import {
  useGetLpAPR,
  useGetVaultBadges,
  useGetVaultOverview,
  useGetVaultOverviewWithoutWallet,
} from "../../../query/vault";
import { StakingOverviewEstimatedReward } from "./StakingOverviewEstimatedReward";
import { useAtomValue, useSetAtom } from "jotai";
import { liquidityModalOpenedAtom } from "../../../store/staking";
import { currentWalletAddressAtom } from "../../../store";
import { NetworkConfiguration } from "../../Network";
import { POLYGON_NETWORK } from "../../ConnectWallet/WalletHelper";
import { useActiveWalletChain } from "thirdweb/react";

const ButtonWrap = styled(Button)`
  align-self: stretch;

  & > div {
    height: 100%;
  }
`;

//move this function to a helper file
function fromWei(value) {
  const convertedValue = value / 10 ** 18 || 0;
  return convertedValue === 0
    ? 0
    : formatStringWithFixedFraction(convertedValue);
}

export function StakingOverviewCULPStats({ onStake, switchChain, chain }) {
  const netconfig = NetworkConfiguration[POLYGON_NETWORK];
  const chainId = useActiveWalletChain();
  const walletAddress = useAtomValue(currentWalletAddressAtom);
  const badgesQuery = useGetVaultBadges();
  const { data = {}, isLoading: isLoadingData } = useGetVaultOverview();
  const { data: dataNoWallet = {}, isLoading: isLoadingNoWallet } =
    useGetVaultOverviewWithoutWallet();
  const setShowLiquidityModal = useSetAtom(liquidityModalOpenedAtom);
  const { data: aprQuery = {}, isLoading: isAprLoading } = useGetLpAPR();

  const isLoading = isLoadingData || isLoadingNoWallet || isAprLoading;

  const handleOpenLiquidityModal = () => {
    setShowLiquidityModal(true);
  };

  // CU Price
  const price = aprQuery.CULP?.CULPValue;

  const totalStaked = data.CULP
    ? data.CULP?.totalStakedCULP
    : dataNoWallet.CULP?.totalStakedCULP;

  // Total CULP Staked section
  const totalStakedCULP = formatStringWithFixedFraction(totalStaked);
  const totalValueStakedLP = formatStringWithFixedFraction(
    data.CULP?.totalValueStakedLP
  );
  const totalCULPStakedInUsd = totalStaked * price;
  const totalCULPStakedInUsdFormatted =
    formatStringWithFixedFraction(totalCULPStakedInUsd);

  const culpBalance = data.CULP?.CULPBalance;
  const disabled = Number(culpBalance) === 0;
  const wrongChain = chainId?.id !== chain?.id;

  // Total Staked section
  const userStakedCULP = data.CULP?.userStakedCULP;
  const userStakedValue = formatStringWithFixedFraction(
    data.CULP?.userStakedValue
  );
  const userSCULPBalance = formatStringWithFixedFraction(
    data.CULP?.userSRCULP / 10 ** 18
  );

  const stakedCULP = fromWei(data.CULP?.userStakedCULP);
  const userStakedInUsd = stakedCULP * price;
  const userStakedInUsdFormatted =
    formatStringWithFixedFraction(userStakedInUsd);

  //Estimated APY section
  const minAPRLP = formatStringWithFixedFraction(
    aprQuery.CULP?.totalMinApr,
    0,
    0
  );
  const maxAPRLP = formatStringWithFixedFraction(
    aprQuery.CULP?.totalMaxApr,
    0,
    0
  );

  //Badge Boost section
  const totalBoostFactor = formatStringWithFixedFraction(
    badgesQuery.data?.totalBoostFactorLP || 0,
    0,
    3
  );

  const footerTitle = walletAddress
    ? "stake culp to join the leaderboard!"
    : "Connect your wallet to view your assets!";

  return (
    <StakingRainbowBorder footerTitle={footerTitle}>
      <StakingOverviewStats
        title="CULP"
        subtitle={`Your Balance: ${fromWei(culpBalance)}`}
        isLoading={isLoading}
        token={StakingTokenTypes.culp}
        actions={
          <>
            <ButtonWrap
              variant="primary"
              size="sm"
              // disabled={disabled}
              onClick={() =>
                wrongChain ? switchChain(chain) : handleOpenLiquidityModal()
              }>
              {wrongChain ? "Switch Network" : "Add Liquidity"}
            </ButtonWrap>
            <ButtonWrap
              disabled={wrongChain ? (disabled ? disabled : null) : disabled}
              variant="secondary"
              size="sm"
              onClick={() => (wrongChain ? switchChain(chain) : onStake())}>
              {wrongChain ? (disabled ? "Stake" : "Switch Network") : "Stake"}
            </ButtonWrap>
          </>
        }>
        <StakingOverviewStatsGrid>
          {walletAddress && (
            <>
              <StakingOverviewStatsSection
                title="My CULP Staked"
                value={
                  <FormattedAmount wei={userStakedCULP ? userStakedCULP : 0} />
                }
                secondValue={`$ ${userStakedInUsdFormatted}`}
              />
              <StakingOverviewStatsSection
                title="Total CULP Staked"
                value={totalStakedCULP}
                secondValue={`$ ${totalCULPStakedInUsdFormatted}`}
              />
              <StakingOverviewStatsSection
                title="sCULP Balance"
                value={userSCULPBalance}
              />
              <StakingOverviewStatsSection
                title="Badge Boost"
                value={totalBoostFactor}
              />
            </>
          )}
          {!walletAddress && (
            <StakingOverviewStatsFullRow>
              <StakingOverviewStatsSection
                title="Total CULP Staked"
                value={totalStakedCULP}
                secondValue={`$ ${totalCULPStakedInUsdFormatted}`}
              />
            </StakingOverviewStatsFullRow>
          )}
          <StakingOverviewStatsFullRow>
            <StakingOverviewStatsSection
              title="Estimated APR"
              tokens={[
                StakingTokenTypes.cu,
                StakingTokenTypes.xai,
                // StakingTokenTypes.arb,
                StakingTokenTypes.grail,
              ]}
              value={
                <StakingOverviewEstimatedReward
                  min={minAPRLP}
                  max={maxAPRLP}
                  token={StakingTokenTypes.culp}
                />
              }
            />
          </StakingOverviewStatsFullRow>
        </StakingOverviewStatsGrid>
      </StakingOverviewStats>
      <LiquidityModal />
      <LiquiditySuccessModal />
    </StakingRainbowBorder>
  );
}
